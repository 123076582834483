import { useEffect } from "react";

const Favicon = () => {
  useEffect(() => {
    const hostName = window.location.hostname;
    let iconPath = "";
    if (hostName === "my.better5.com") {
      iconPath = "/images/b5-icon.png";
    }
    if (!iconPath) {
      return;
    }
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      document.head.appendChild(newLink);
    }
    document.querySelector("link[rel~='icon']").href = iconPath;
  }, []);

  return null;
};

export default Favicon;
